import { graphql } from "gatsby";
import React from "react";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import Layout from "../components/Layout";
import SidebarAccount from "../components/SidebarAccount";
import UNDPPALogo from "../assets/images/undppa-logo.png";
import UNDPPAICLogo from "../assets/images/undppa-ic-logo.png";

const Help = (props) => {
  const {
    region_data: { unique_region_text },
  } = props;
  const { t } = useTranslation();

  return (
    <Layout pageName="help">
      <Helmet>
        <title>{unique_region_text?.siteTitle ?? ""}</title>
      </Helmet>
      <main className="section-container help-container">
        <header id="documentation-and-help">
          <h1 className="h2">
            <FontAwesomeIcon className="mr-3" icon={faQuestionCircle} />
            <Trans i18nKey="helpTitle">Documentation and Help</Trans>
          </h1>
        </header>

        <section id="undppa-intro" className="help-section">
          <Image
            src={UNDPPALogo}
            alt={t("UNDPPA Logo")}
            className="logo pb-3"
          />
          <h4>
            <Trans i18nKey="undppaAboutTitle">About the UNDPPA</Trans>
          </h4>
          <p>
            <Trans i18nKey="undppaAboutDescription">
              The United Nations Department of Political and Peacebuilding
              Affairs plays a central role in United Nations efforts to prevent
              deadly conflict and build sustainable peace around the world. The
              DPPA monitors and assesses global political developments with an
              eye to detecting potential crises and devising effective
              responses. The department provides support to the
              Secretary-General and his envoys in their peace initiatives, as
              well as to the UN political missions around the world.
            </Trans>
          </p>
        </section>

        <section className="help-section" id="unddpa-ic-intro">
          <Image
            src={UNDPPAICLogo}
            alt={t("UNDPPA Innovation Cell Logo")}
            className="logo pb-3"
          />
          <h4>
            <Trans i18nKey="undppaicAboutTitle">
              About the UNDPPA Innovation Cell
            </Trans>
          </h4>
          <p>
            <Trans i18nKey="undppaicAboutDescription1">
              DPPA launched the Innovation Cell in January 2020, an
              interdisciplinary team that helps the Department and its field
              operations explore, pilot, and scale new technologies, tools, and
              practices for conflict prevention, mediation, and peacebuilding.
              In alignment with the Secretary-General’s call to accelerate
              innovative methods across the UN system and his policy agenda on
              UN 2.0, the Innovation Cell catalyzes advancements in peace and
              security while fostering collaborative human-centered design and
              problem-solving among colleagues at UNHQ and in the field.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="undppaicAboutDescription2">
              Developed by DPPA’s Innovation Cell in collaboration with Element
              84 and academic partners, Geoguard is the first-ever geospatial
              dashboard for decision-makers with insight on environmental and
              conflict risks across the Middle East, Central, and West Africa.
              Using cutting-edge technology, Geoguard aggregates millions of
              data points on environmental conditions and geo-coded conflict
              events in an intuitive dashboard. It incorporates diverse data
              sources, including locally collected or crowd-sourced information,
              to provide insight on environmental risk-factors throughout
              specified regions and snapshots. The project was made possible
              with funds from DPPA’s Multi-Year Appeal and the Complex Risk
              Analytics Fund (CRAF’d).
            </Trans>
          </p>
        </section>
      </main>
      <SidebarAccount {...props} />
    </Layout>
  );
};

export default connect((state) => state)(Help);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
